import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { ToastsStore } from "react-toasts";
import Api from "../../Api";
import Header from "../../Components/Header";
import { authContext } from "../../Contexts/AuthContext";
import "./Dashboard.css";

const Dashboard = () => {
    const { auth } = useContext(authContext);
    const [isLoading, setLaoding] = useState(true);
    const [tournament, setTournament] = useState([]);
    //Get tournament data from the server
    useEffect(() => {
        Api.getTournamentList(auth.data)
        .then((result) => {
            setLaoding(false);
            let response = result.data;
            if(response.success) {
                //console.log(response.data);
                setTournament(response.data);
            } else {
                ToastsStore.error(response.message);
            }
        })
        .catch((error) => {
            setLaoding(false);
            ToastsStore.error('Looks like there was a problem');
            console.log('Looks like there was a problem: \n', error);
        });
    }, [setTournament]);

    return(
        <>
            <div className={`loader ${isLoading ? 'show'  : 'hide'}`}>
                <div className="container align-items-center">
                    <span className="mr-2"><ScaleLoader color={'#ed1d25'} /></span>
                    <span>Please hold on while we are fetching your tournament list from the server</span>
                </div>
            </div>
            <Header back_url="/login" />
            <main>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-header d-flex justify-content-center">
                                <h4 className="page-title">Tournament View</h4>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="table-responsive-sm table-responsive-md">
                            <table className="table table-bordered table-hover mobile-view text-center">
                                <thead className="bg-light">
                                    <tr>
                                        <th with="60%">Tournament Name</th>
                                        <th with="8%">Week Of</th>
                                        <th with="8%">Start Date</th>
                                        <th with="8%">End Date</th>
                                        <th with="16%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {tournament.map((item, i) => {
                                    return (<tr key={i}>
                                        <td className="text-left">
                                            <div className="d-flex flex-column">
                                                <span className="thshow">Tournament Name</span>
                                                <span className="text-inline">
                                                    {item.name}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="col-4-lt">
                                            <div>
                                                <span className="thshow">Week Of</span>
                                                {item.weekof_date}
                                            </div>
                                        </td>
                                        <td className="col-4-lt">
                                            <div>
                                                <span className="thshow">Start Date</span>
                                                {item.start_date}
                                            </div>
                                        </td>
                                        <td className="col-4-lt">
                                            <div>
                                                <span className="thshow">End Date</span>
                                                {item.end_date}
                                            </div>
                                        </td>
                                        <td className="action">
                                            <div>
                                                <span className="thshow">Action</span>
                                                <Link to={`/tournament-match-list-${item.tournament_id}`} className="btn btn-primary btn-mini btn-block">Play Match</Link>
                                            </div>
                                        </td>
                                    </tr>);
                                })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Dashboard;