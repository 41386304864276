import React from 'react';

const CategoryDropdown = (props) => {
    let curCategory = [];
    let categoryArr = props.categoryArr;
    if(categoryArr.hasOwnProperty(props.gender)) {
        curCategory = categoryArr[props.gender];
    }
    //console.log(curCategory);

    return (
        <>
            {curCategory.map((item, i) => {
                return <option key={i} value={item.id}>{item.short_code}</option>;
            })}
        </>
    );
}

export default CategoryDropdown;