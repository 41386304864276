import React, { useEffect, useState } from "react"
import RepeatIcon from "@material-ui/icons/Repeat";

const PlayerView = (props) => {
    let leftPlayer = props.match.left;
    let rightPlayer = props.match.right;
    let playerPoint = props.playerPoint;
    //console.log(props.match);
    //Left Player: Add points
    if(typeof(leftPlayer) !== 'undefined') {
        let points = playerPoint[leftPlayer.player_index];
        Object.keys(points).map((key) => {
            leftPlayer[key] = points[key];
        })
    }
    //Right Player: Add points
    if(typeof(rightPlayer) !== 'undefined') {
        let points = playerPoint[rightPlayer.player_index];
        Object.keys(points).map((key) => {
            rightPlayer[key] = points[key];
        })
    }

    return (
        <>
            <div className="box-buttons">
                <div className="row mb-2">
                    <div className="col-4">
                        <div className="bg-light text-center font-weight-bold">
                            <span className="d-block text-uppercase">Ball Change</span>
                            <span className="d-block">7-9</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <button className="btn btn-danger w-100 h-100">Undo</button>
                    </div>
                    <div className="col-4">
                        <div className="bg-light d-flex align-items-center">
                            <h3 className="font-weight-bold mb-0 mx-auto">36:45</h3>
                        </div>
                    </div>
                </div>
                <div className="player-square mb-2 text-center">
                    <div className="row line-bottom">
                        <div className="col-6 p-3 line-right">Player-1</div>
                        <div className="col-6 p-3 line-left">Player-2</div>
                    </div>
                    <div className="row line-top">
                        <div className="col-6 p-3 line-right">Player-1</div>
                        <div className="col-6 p-3 line-left">Player-2</div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-2">
                    <p className="text-danger mb-0">{props.alertMessage}</p>
                </div>
                <div className="row mb-2">
                    <div className="col-3">
                        <button className="btn btn-sm btn-warning w-100 h-100">NET</button>
                    </div>
                    <div className="col-3">
                        <button className="btn btn-sm btn-success w-100 h-100">ACE</button>
                    </div>
                    <div className="col-3">
                        <button className="btn btn-sm btn-info w-100 h-100">FAULT</button>
                    </div>
                    <div className="col-3">
                        <button className="btn btn-sm btn-primary w-100 h-100">F FAULT</button>
                    </div>
                </div>
            </div>
            <div className="box-score">
                <div className="row d-flex align-items-stretch">
                    <div className="col-5">
                        {(typeof(leftPlayer) !== 'undefined') ? (
                            <div className={`box-player text-center ${leftPlayer.player_bg}`} onClick={(e)=>props.winPoint(e,leftPlayer.player_index,leftPlayer.score)}>
                                <div className={`score service ${(leftPlayer.service===1) ? 'ball-right' : ''}`}>{leftPlayer.point}</div>
                                <div className="image">
                                    {(leftPlayer.partner_image) ? (
                                        <div className="img-box">
                                            <img src={leftPlayer.player_image} className="img-fluid w-50" alt="image" />
                                            <img src={leftPlayer.partner_image} className="img-fluid w-50" alt="image" />
                                        </div>
                                    ) : (
                                        <img src={leftPlayer.player_image} className="img-fluid" alt="image" />
                                    )}
                                </div>
                                <div className="player-name p-1">
                                    {(leftPlayer.partner_name) ? (
                                        <div className="name-box">
                                            <span className="d-block player">{leftPlayer.player_name}</span>
                                            <span className="d-block partner">{leftPlayer.partner_name}</span>
                                        </div>
                                    ) : (
                                        <span className="d-block player">{leftPlayer.player_name}</span>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="box-player text-center">Left Undefined</div>
                        )}
                    </div>
                    <div className="col-2">
                        <div className="d-flex align-items-center h-100">
                            <button className="btn btn-sm btn-primary" onClick={props.swapPlayer}><RepeatIcon /></button>
                        </div>
                    </div>
                    <div className="col-5">
                        {(typeof(rightPlayer) !== 'undefined') ? (
                            <div className={`box-player text-center ${rightPlayer.player_bg}`} onClick={(e)=>props.winPoint(e,rightPlayer.player_index,rightPlayer.score)}>
                                <div className={`score service ${(rightPlayer.service===1) ? 'ball-left' : ''}`}>{rightPlayer.point}</div>
                                <div className="image">
                                    {(rightPlayer.partner_image) ? (
                                        <div className="img-box">
                                            <img src={rightPlayer.player_image} className="img-fluid w-50" alt="image" />
                                            <img src={rightPlayer.partner_image} className="img-fluid w-50" alt="image" />
                                        </div>
                                    ) : (
                                        <img src={rightPlayer.player_image} className="img-fluid" alt="image" />
                                    )}                                    
                                </div>
                                <div className="player-name p-1">
                                    {(rightPlayer.partner_name) ? (
                                        <div className="name-box">
                                            <span className="d-block player">{rightPlayer.player_name}</span>
                                            <span className="d-block partner">{rightPlayer.partner_name}</span>
                                        </div>
                                    ) : (
                                        <span className="d-block player">{rightPlayer.player_name}</span>
                                    )}
                                </div>                          
                            </div>
                        ) : (
                            <div className="box-player text-center">Right Undefined</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PlayerView;