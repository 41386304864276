import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const LoaderText = (props) => {
    return (
        <>
            <div className={`loader ${props.status ? 'show'  : 'hide'}`}>
                <div className="container align-items-center">
                    <span className="mr-2"><ScaleLoader color={'#ed1d25'} /></span>
                    <span>Please hold on while we are fetching your {props.text} from the server</span>
                </div>
            </div>
        </>
    );
}
export default LoaderText;