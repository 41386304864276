import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import ScaleLoader from "react-spinners/ScaleLoader";
import Api from "../../Api";
import "./Login.css";
import Header from "../../Components/Header";
import { authContext } from "../../Contexts/AuthContext";

const Login = () => {
    const {register, handleSubmit, errors} = useForm();
    const [isLoading, setLaoding] = useState(false);
    const history = useHistory();
    const { setAuthData } = useContext(authContext);
    const onSubmit = (data) => {
        //console.log(data);
        setLaoding(true);
        //Typically here we send a request to our API and in response, we receive the user token.
        Api.userLogin(data)
        .then((result) => {
            let response = result.data;
            if(response.success) {
                let userData = response.data;
                setTimeout( () => {
                    window.localStorage.clear();
                    window.localStorage.setItem('language', 'tha');
                    setAuthData(userData);
                    history.push("/dashboard");
                }, 2000);
            } else {
                setLaoding(false);
                localStorage.clear();
                ToastsStore.error(response.message);
            }
        })
        .catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }
    return(
        <>
            <div className={`loader ${isLoading ? 'show'  : 'hide'}`}>
                <ScaleLoader color={'#ed1d25'} />
            </div>
            <Header back_url="/" />
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4 col-sm-8">
                        <div className="login-box col-md-12">
                            <h1 className="text-center text-info">Login</h1>
                            <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="form-group">
                                    <label htmlFor="login_id">Login ID</label>
                                    <input 
                                        type="text" 
                                        name="login_id" 
                                        className="form-control text-capitalize"
                                        ref={register({ required: true })} 
                                    />
                                    {errors.login_id && <small className="error">Login ID field is required.</small>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input 
                                        type="password" 
                                        name="password" 
                                        className="form-control"
                                        aira-invalid={errors.password ? 'true' : 'false'}
                                        ref={register({ required: true, minLength: 6 })}
                                    />
                                    {errors.password && errors.password.type==="required" && (<small className="error">Password field is required.</small>)}
                                    {errors.password && errors.password.type==="minLength" && (<small className="error">Password must be 6 characters in length.</small>)}
                                </div>
                                <input type="submit" className="btn btn-primary w-100" value="Sign-In" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;