import React, { Component } from 'react';
import Header from "../../Components/Header";
import LoaderText from "../../Components/LoaderText";
import "./MatchScore.css";
import PlayerView from './PlayerView';
import ScoreCard from './ScoreCard';

class MatchScore extends Component {
    constructor() {
        super();
        this.state = {
            spinnerText: 'spinners text',
            spinnerStatus: true,
            tournament: {},
            dbMatch: {},
            match: {},
            scoringFormat: 0,
            alertMessage: '',
            playerPoint: {
                1:{'deuce':0, 'game_score':{'s1':0, 's2':0, 's3':0}, 'point':0, 'score':0},
                2:{'deuce':0, 'game_score':{'s1':0, 's2':0, 's3':0}, 'point':0, 'score':0},
            },
            playSet: 's1',
            totalGame: {'s1':0, 's2':0, 's3':0},
            totalDeuce: {'s1':0, 's2':0, 's3':0},
        }
        this.setMatchPlayer = this.setMatchPlayer.bind(this);
    }
    /**
     * Get data from localstorage
     */
    componentDidMount() {
        let tourArr = JSON.parse(window.localStorage.getItem('tournament'));
        let matchArr = JSON.parse(window.localStorage.getItem('match'));
        this.setMatchPlayer(matchArr);
        this.setState({
            spinnerStatus: false,
            spinnerText: 'match data',
            tournament: tourArr,
            dbMatch: matchArr,
            scoringFormat: parseInt(matchArr.match_format)
        });
    }
    /**
     * Set players Left/Right of the Chair Umpire
     * @param {*} matchArr 
     */
    setMatchPlayer = (matchArr) => {
        //let match = this.state.match;
        if(Object.keys(matchArr).length>0) {
            let leftPlayer = {};
            let rightPlayer = {};
            if(matchArr.chair_left===matchArr.player_one_id) {
                //Player-1:Left of the chair umpire
                leftPlayer = {
                    'player_index': 1,
                    'service': (matchArr.service===matchArr.player_one_id) ? 1 : 0,
                    'player_bg': matchArr.player_one_bg,
                    'player_id': matchArr.player_one_id,
                    'player_name': matchArr.player_one_name,
                    'player_image': matchArr.player_one_image,
                    'partner_id': matchArr.player_one_partner_id,
                    'partner_name': matchArr.partner_one_name,
                    'partner_image': matchArr.partner_one_image,
                };
                //Player-2:Right of the chair umpire
                rightPlayer = {
                    'player_index': 2,
                    'service': (matchArr.service===matchArr.player_two_id) ? 1 : 0,
                    'player_bg': matchArr.player_two_bg,
                    'player_id': matchArr.player_two_id,
                    'player_name': matchArr.player_two_name,
                    'player_image': matchArr.player_two_image,
                    'partner_id': matchArr.player_two_partner_id,
                    'partner_name': matchArr.partner_two_name,
                    'partner_image': matchArr.partner_two_image,
                };
            }
            if(matchArr.chair_left===matchArr.player_two_id) {
                //Player-2:Left of the chair umpire
                leftPlayer = {
                    'player_index': 2,
                    'service': (matchArr.service===matchArr.player_two_id) ? 1 : 0,
                    'player_bg': matchArr.player_two_bg,
                    'player_id': matchArr.player_two_id,
                    'player_name': matchArr.player_two_name,
                    'player_image': matchArr.player_two_image,
                    'partner_id': matchArr.player_two_partner_id,
                    'partner_name': matchArr.partner_two_name,
                    'partner_image': matchArr.partner_two_image,
                };
                //Player-1:Right of the chair umpire
                rightPlayer = {
                    'player_index': 1,
                    'service': (matchArr.service===matchArr.player_one_id) ? 1 : 0,
                    'player_bg': matchArr.player_one_bg,
                    'player_id': matchArr.player_one_id,
                    'player_name': matchArr.player_one_name,
                    'player_image': matchArr.player_one_image,
                    'partner_id': matchArr.player_one_partner_id,
                    'partner_name': matchArr.partner_one_name,
                    'partner_image': matchArr.partner_one_image,
                };
            }
            matchArr['left'] = leftPlayer;
            matchArr['right'] = rightPlayer;
        }
        this.setState({match:matchArr});
    }
    //Swap player positions
    swapPlayer = (e) => {
        e.preventDefault();
        let matchArr = this.state.match;
        if(Object.keys(matchArr).length > 0) {
            let tempPosition = matchArr.chair_left;
            matchArr['chair_left'] = matchArr.chair_right;
            matchArr['chair_right'] = tempPosition;
        }
        this.setState({match:matchArr});
        this.setMatchPlayer(matchArr);
    }
    //Change player service
    changeService = (gscoreCount) => {
        let matchArr = this.state.dbMatch;
        if(Object.keys(matchArr).length > 0) {
            if(gscoreCount%2 === 0) {//For Even number
                matchArr['service'] = matchArr['service_first'];
            } else {//For Odd number
                if(matchArr['service_first']===matchArr['player_one_id']) {
                    matchArr['service'] = matchArr['player_two_id'];
                } else {
                    matchArr['service'] = matchArr['player_one_id'];
                }
            }
        }
        this.setState({match:matchArr});
        this.setMatchPlayer(matchArr);
    }
    //Players win points
    winPoint = (e, player, score) => {
        console.log('Click on player:'+player);
        e.preventDefault();
        let pointsArr = [0,15,30,40];
        let playerPoint = this.state.playerPoint;
        score = score + 1;//Increment score
        if(player===1) {//Player One
            let gameScore = this.setGameScore(score, playerPoint[player]['game_score'], playerPoint[2]);
            playerPoint[player] = {
                'deuce': 0,
                'game_score': gameScore.gscore,
                'point': pointsArr[gameScore.score],
                'score': gameScore.score,
            }
        } else {//Player Two
            let gameScore = this.setGameScore(score, playerPoint[player]['game_score'], playerPoint[1]);
            playerPoint[player] = {
                'deuce': 0,
                'game_score': gameScore.gscore,
                'point': pointsArr[gameScore.score],
                'score': gameScore.score,
            }
        }
        //Set current updated data
        this.setState(playerPoint);
        //console.log(playerPoint);
    }
    //Set game score
    setGameScore = (score, gscore, player) => {
        let totalGame = this.state.totalGame;
        let tmpGScore = gscore[this.state.playSet];        
        let result = {'score':score, 'gscore':gscore};
        if(score===4 && player.score<=(4-2)) {
            tmpGScore = tmpGScore + 1;
            result = {
                'score': 0,
                'gscore': {
                    's1': (this.state.playSet==='s1') ? tmpGScore : 0,
                    's2': (this.state.playSet==='s2') ? tmpGScore : 0,
                    's3': (this.state.playSet==='s3') ? tmpGScore : 0,
                }
            }
            let gscoreCount = tmpGScore + player.game_score[this.state.playSet];
            totalGame[this.state.playSet] = gscoreCount;
            this.changeService(gscoreCount);
        }
        this.setState(totalGame);
        return result;
    }

    render() {
        return (
            <>
                <LoaderText 
                    text={this.state.spinnerText}
                    status={this.state.spinnerStatus}
                />
                <Header back_url="/tournament-match-properties" />
                <main>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-header d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Match Score</h6>
                                    {Object.keys(this.state.tournament).length > 0 ? (
                                        <h6 className="mb-0">Tournament: {this.state.tournament.name}, WeekOf: {this.state.tournament.weekof_title}</h6>
                                    ) : (
                                        <h6 className="mb-0">Tournament: Invalid tournament</h6>
                                    )}                                    
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mobile-app mx-auto">
                                    <ScoreCard
                                        playSet={this.state.playSet}
                                        match={this.state.match}
                                        totalGame={this.state.totalGame}
                                        playerPoint={this.state.playerPoint}
                                    />
                                    <PlayerView 
                                        alertMessage={this.state.alertMessage}
                                        match={this.state.match}
                                        playerPoint={this.state.playerPoint}
                                        winPoint={this.winPoint}
                                        swapPlayer={this.swapPlayer}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default MatchScore;