import React from 'react';

const RoundDropdown = (props) => {
    let roundArr = [];
    if(props.roundGroup.hasOwnProperty(props.gender)) {
        let roundByGender = props.roundGroup[props.gender];
        if(roundByGender.hasOwnProperty(props.category_id)) {
            roundArr = roundByGender[props.category_id];
        }
    }
    //console.log(roundArr);

    return (
        <>
            {roundArr.map( (item, i) => {
                return <option key={i} value={item.keys}>{item.vals}</option>;
            })}            
        </>
    );
}

export default RoundDropdown;