import React from "react";
import Header from "../../Components/Header";

const NotFound = () => {
    return(
        <>
            <Header />
            <div className="container-fluid">
                <div className="d-flex flex-row align-items-center vh-100">
                    <h1 className="w-100 text-center">!404 Page Not Found</h1>
                </div>
            </div>
        </>
    );
}

export default NotFound;