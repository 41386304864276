import React, { useContext } from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from "react-router-dom";
import { authContext } from "../Contexts/AuthContext";

const Header = (props) => {
    const { setAuthData, auth } = useContext(authContext);
    const language = window.localStorage.getItem('language');
    const onLogOut  = () => {
        console.log('logout proceed...');
        setAuthData(null);
    }
    let userMenu = [];
    //After logged in menu
    if(auth.data) {
        let fullName = auth.data.thai_first_name+' '+auth.data.thai_family_name;
        if(language.toLowerCase()==='eng') {
            fullName = auth.data.first_name+' '+auth.data.family_name;
        }
        userMenu.push(
            <ul key="0" className="navbar-nav flex-row ml-md-auto d-md-flex">
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Welcome {fullName} <AccountCircleIcon/>
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link to="/profile" className="dropdown-item">Update Profile</Link>
                        <Link to="/change-password" className="dropdown-item">Change Password</Link>
                        <div className="dropdown-divider"></div>
                        <Link to="#" onClick={onLogOut} className="dropdown-item">Logout</Link>
                    </div>
                </li>
            </ul>
        );
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">            
            <Link to={props.back_url} className="text-red ml-2 mr-auto">
                <KeyboardBackspaceIcon />
            </Link>
            {userMenu}
        </nav>
    );
}

export default Header;