import React from "react";
import { Link } from "react-router-dom";
import "./Landing.css";

const Landing = () => {
    return(
        <>
            <div className="landing-page text-center">
                <div className="container-fluid">
                    <h1>{process.env.REACT_APP_SITE_NAME}</h1>
                    <h2>{process.env.REACT_APP_SITE_SLAG}</h2>
                    <div className="logo align-middle">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} className="img-fluid" alt="logo" />
                    </div>
                </div>
                <footer>
                    <div className="container-fluid">
                        <Link to="/login" className="d-block btn btn-lg bg-blue">Login</Link>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Landing;