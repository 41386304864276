import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ScaleLoader from "react-spinners/ScaleLoader";
import Api from "../../Api";
import "./MatchProperty.css";
import Header from "../../Components/Header";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const MatchProperty = () => {
    //Get tournament ID from the URL
    const { match_id } = useParams();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [loaderText, setLoaderText] = useState('Please wait...');
    const [isLoading, setLaoding] = useState(true);
    const [tournament, setTournament] = useState([]);
    const [matchFormat, setMatchFormat] = useState([]);
    const [match, setMatch] = useState({});
    const [position, setPosition] = useState({'chair_left':'', 'chair_right':''});
    /**
     * Get match data from the localstorage
     */
    useEffect(()=>{
        setLoaderText('match data');        
        let matchArr = JSON.parse(window.localStorage.getItem('match'));
        let tourArr = JSON.parse(window.localStorage.getItem('tournament'));
        setTournament(tourArr);
        setMatch(matchArr);
        //Fetch match format from the server
        Api.getMatchFormat()
        .then((result) => {
            setLaoding(false);
            let response = result.data;
            if(response.success) {
                setMatchFormat(response.data);
            } else {
                setMatchFormat([]);
            }
        })
        .catch((error) => {
            setLaoding(false);
            ToastsStore.error('Looks like there was a problem');
            console.log('Looks like there was a problem: \n', error);
        });        
    }, [setLoaderText, setTournament, setMatch, setMatchFormat]);
    /**
     * Handle player position of the chair umpire (Left/Right)
     */
    const handelPosition = (posLR, e) => {
        e.preventDefault();
        let curPlayer = e.target.value;
        let curPosition = {};
        if(posLR.toLowerCase()==='left') {
            curPosition['chair_left'] =  curPlayer;
            curPosition['chair_right'] = (curPlayer===match.player_one_id) ? match.player_two_id : match.player_one_id;
        } else {
            curPosition['chair_right'] = curPlayer;
            curPosition['chair_left'] = (curPlayer===match.player_one_id) ? match.player_two_id : match.player_one_id;
        }
        setPosition(curPosition);
    }
    /**
     * On submit match property form, update value into localstorage
     */
    const onSubmit = (data) => {
        if(Object.keys(data).length > 0) {
            match['player_one_bg'] = 'bg-red';
            match['player_two_bg'] = 'bg-yellow';
            match["won_toss"] = data.won_toss;
            match["made_choice"] = data.made_choice;
            match["chair_left"] = data.chair_left;
            match["chair_right"] = data.chair_right;
            match["service_first"] = data.service_first;
            match["service"] = data.service_first;
            match["match_format"] = data.match_format;
        }
        window.localStorage.removeItem("match");
        window.localStorage.setItem("match", JSON.stringify(match));
        history.push("tournament-match-score");
    }
    
    return (
        <>
            <div className={`loader ${isLoading ? 'show'  : 'hide'}`}>
                <div className="container align-items-center">
                    <span className="mr-2"><ScaleLoader color={'#ed1d25'} /></span>
                    <span>Please hold on while we are fetching your {loaderText} from the server</span>
                </div>
            </div>
            <Header back_url={`/tournament-match-list-${tournament.id}`} />
            <main>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-header d-flex justify-content-between align-items-center">
                                <h6 className="mb-0">Set Match Property</h6>
                                <h6 className="mb-0">Tournament: {tournament.name}, WeekOf: {tournament.weekof_title}</h6>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="mobile-app mx-auto">
                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                    <div className="form-group">
                                        <label htmlFor="won_toss">Who won the toss?</label>
                                        <select className="form-control form-control-sm" name="won_toss" id="won_toss" ref={register({required:true})}>
                                            <option value="">Select</option>
                                            <option key="1" value={match.player_one_id}>{match.player_one_name}</option>
                                            <option key="2" value={match.player_two_id}>{match.player_two_name}</option>
                                        </select>
                                        {errors.won_toss && <small className="error">Won the toss field is required.</small>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="made_choice">Who made the choice?</label>
                                        <select className="form-control form-control-sm" name="made_choice" id="made_choice" ref={register({required:true})}>
                                            <option value="">Select</option>
                                            <option key="1" value={match.player_one_id}>{match.player_one_name}</option>
                                            <option key="2" value={match.player_two_id}>{match.player_two_name}</option>
                                        </select>
                                        {errors.made_choice && <small className="error">Made the choice field is required.</small>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="chair_left">Who left of the chair?</label>
                                        <select className="form-control form-control-sm" name="chair_left" id="chair_left" ref={register({required:true})} value={position.chair_left} onChange={(e)=>handelPosition("left", e)}>
                                            <option value="">Select</option>
                                            <option key="1" value={match.player_one_id}>{match.player_one_name}</option>
                                            <option key="2" value={match.player_two_id}>{match.player_two_name}</option>
                                        </select>
                                        {errors.chair_left && <small className="error">Left of the chair field is required.</small>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="chair_right">Who right of the chair?</label>
                                        <select className="form-control form-control-sm" name="chair_right" id="chair_right" ref={register({required:true})} value={position.chair_right} onChange={(e)=>handelPosition("right", e)}>
                                            <option value="">Select</option>
                                            <option key="1" value={match.player_one_id}>{match.player_one_name}</option>
                                            <option key="2" value={match.player_two_id}>{match.player_two_name}</option>
                                        </select>
                                        {errors.chair_right && <small className="error">Right of the chair field is required.</small>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="service_first">Who service first?</label>
                                        <select className="form-control form-control-sm" name="service_first" id="service_first" ref={register({required:true})}>
                                            <option value="">Select</option>
                                            <option key="1" value={match.player_one_id}>{match.player_one_name}</option>
                                            <option key="2" value={match.player_two_id}>{match.player_two_name}</option>
                                        </select>
                                        {errors.service_first && <small className="error">Service first field is required.</small>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="match_format">Match Format</label>
                                        <select className="form-control form-control-sm" name="match_format" name="match_format" id="match_format" ref={register({required:true})}>
                                            <option value="">Select</option>
                                            { (matchFormat.length > 0) ? (
                                                matchFormat.map((item, i) =>{
                                                    return <option key={i} value={item.id}>{item.title}</option>
                                                })
                                            ) : (
                                                <option value="0">Select</option>
                                            )}
                                        </select>
                                        {errors.match_format && <small className="error">Match format field is required.</small>}
                                    </div>
                                    <div className="form-group mb-0">
                                        <button type="submit" className="btn btn-primary btn-mini btn-block">To Continue <DoubleArrowIcon fontSize="small" /></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default MatchProperty;