import axios from 'axios';

//Get parameters from url by key
/*function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for(var i=0; i<vars.length; i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable) {
            return pair[1];
        }
    }
    return (false);
}*/
// Create instance called instance
let siteUrl = process.env.REACT_APP_DOMAIN_TEST +'webservices/livescore1.0.0/';
if(process.env.REACT_APP_ENVIORMENT==='production') {
    siteUrl = process.env.REACT_APP_DOMAIN_LIVE +'webservices/livescore1.0.0/';
}
const instance = axios.create({
    baseURL: siteUrl,
    headers: {
        'content-type': 'application/json',
        'Client-Service': 'frontend-client',
        'Auth-Key': process.env.REACT_APP_AUTH_KEY
    }
});
const language = window.localStorage.getItem('language');
const enviorment = process.env.REACT_APP_ENVIORMENT;

const Api = {
    //User login: Get user data from database
    userLogin: (params) =>
    instance({
        'method': 'post',
        'url': 'loginApi/userLogin',
        'data': {
            'username': params.login_id,
            'password': params.password
        }
    }),
    //Get user tournament data from the server
    getTournament: (tour_id) =>
    instance({
        'method': 'get',
        'url': 'tournamentApi/getTourDetails',
        'params': {
            'enviorment': enviorment,
            'language': language,
            'tour_id': tour_id
        }
    }),
    //Get user tournament list data from the server
    getTournamentList: (params) =>
    instance({
        'method': 'get',
        'url': 'tournamentApi/getChariUmpireTournament',
        'params': {
            'enviorment': enviorment,
            'language': language,
            'user_primary_id': params.id
        }
    }),
    //Get match list by tournament id from the server
    getTournamentMatchList: (params) =>
    instance({
        'method': 'get',
        'url': 'tournamentApi/getMatchList',
        'params': {
            'enviorment': enviorment,
            'language': language,
            'tour_id': params.tour_id,
            'category_id': params.category_id,
            'gender': params.gender,
            'round': params.round
        }
    }),
    //Get match scroing format data from the server
    getMatchFormat: () =>
    instance({
        'method': 'get',
        'url': 'tournamentApi/getMatchFormat',
        'params': {
            'enviorment': enviorment,
            'language': language,
        }
    }),
};
export default Api;