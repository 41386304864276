import React, { useEffect, useState } from "react";

const ScoreCard = (props) => {
    //console.log(props.playerPoint);
    let match = props.match;
    let totalGame = props.totalGame;    
    let set1Score = {'s1':0, 's2':0, 's3':0};
    let set2Score = {'s1':0, 's2':0, 's3':0};
    //Define player set score
    Object.keys(props.playerPoint).map((key) => {
        let gscore = props.playerPoint[key]['game_score'];
        if(parseInt(key)===1) {//For Player One
            set1Score = gscore;
        } else {//For Player Two
            set2Score = gscore;
        }
    });

    return (
        <>
            <div className="score-card">
                <table className="table mb-2">
                    <thead>
                        <tr>
                            <th className="bg-white">Players / Team</th>
                            <th className="bg-yellow">1</th>
                            <th className="bg-yellow">2</th>
                            <th className="bg-yellow">3</th>
                            <th colSpan="6" className="bsolid-left bg-dark text-white">GAME-{totalGame[props.playSet]}</th>
                            <th colSpan="2" className="bsolid-left text-white bg-danger">DEUCE-0</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={match.player_one_bg}>
                                {(match.player_one_partner_id) ? (
                                    <div className="player">
                                        <div className="d-flex justify-content-start">
                                            <img src={match.player_one_image} className="img-fluid rounded w-15 mr-1" alt="Player Image" />
                                            <span className="player">{match.player_one_name}</span>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <img src={match.partner_one_image} className="img-fluid rounded w-15 mr-1" alt="Player Image" />
                                            <span className="player">{match.partner_one_name}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="player">
                                        <div className="d-flex justify-content-start">
                                            <img src={match.player_one_image} className="img-fluid rounded w-15 mr-1" alt="Player Image" />
                                            <span className="player">{match.player_one_name}</span>
                                        </div>
                                    </div>
                                )}
                            </td>
                            <td className="box-size set-score">{set1Score.s1}</td>
                            <td className="box-size set-score">{set1Score.s2}</td>
                            <td className="box-size set-score">{set1Score.s3}</td>
                            <td className="box-size bsolid-left">1</td>
                            <td className="box-size">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size bsolid-left">0</td>
                            <td className="box-size">0</td>
                        </tr>
                        <tr>
                            <td className={match.player_two_bg}>
                                {(match.player_two_partner_id) ? (
                                    <div className="player">
                                        <div className="d-flex justify-content-start">
                                            <img src={match.player_two_image} className="img-fluid rounded w-15 mr-1" alt="Player Image" />
                                            <span className="player">{match.player_two_name}</span>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <img src={match.partner_two_image} className="img-fluid rounded w-15 mr-1" alt="Player Image" />
                                            <span className="player">{match.partner_two_name}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="player">
                                        <div className="d-flex justify-content-start">
                                            <img src={match.player_two_image} className="img-fluid rounded w-15 mr-1" alt="Player Image" />
                                            <span className="player">{match.player_two_name}</span>
                                        </div>
                                    </div>
                                )}
                            </td>
                            <td className="box-size set-score">{set2Score.s1}</td>
                            <td className="box-size set-score">{set2Score.s2}</td>
                            <td className="box-size set-score">{set2Score.s3}</td>
                            <td className="box-size bsolid-left">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size">0</td>
                            <td className="box-size bsolid-left">0</td>
                            <td className="box-size">0</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ScoreCard;