import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import ScaleLoader from "react-spinners/ScaleLoader";
import Api from "../../Api";
import "./MatchList.css";
import Header from "../../Components/Header";
import { useHistory, useParams } from "react-router-dom";
import CategoryDropdown from "./CategoryDropdown";
import RoundDropdown from "./RoundDropdown";

const MatchList = () => {
    const history = useHistory();
    const [loaderText, setLoaderText] = useState('Please wait...');
    const [isLoading, setLaoding] = useState(true);
    const [tournament, setTournament] = useState([]);
    const [gender, setGender] = useState('');
    const [genderArr, setGenderArr] = useState([]);
    const [category_id, setCategoryId] = useState(0);
    const [categoryArr, setCategoryArr] = useState({});
    const [round, setRound] = useState('');
    const [roundGroup, setroundGroup] = useState({});
    const [matches, setMatches] = useState([]);
    //Get tournament ID from the URL
    const { tour_id } = useParams();
    //Get tournament match list from the server
    useEffect(() => {
        //Get tournament details
        setLoaderText('tournament details');
        Api.getTournament(tour_id)
        .then((result) => {            
            let tourResponse = result.data;
            if(tourResponse.success) {
                setLaoding(false);
                let tourData = tourResponse.data;
                //Set tournament details
                setTournament({
                    'id': tourData.id,
                    'weekof_date': tourData.weekof_date,
                    'weekof_title': tourData.weekof_title,
                    'name': tourData.name,
                    'short_name': tourData.short_name,
                });
                //Set gender
                setGender(tourData.gender);
                setGenderArr(tourData.genderArr);
                //Set tournament categories
                setCategoryId(tourData.category_id);
                setCategoryArr(tourData.categoryArr);
                //Set Round and Group
                setRound(tourData.round);
                setroundGroup(tourData.roundAndGroup);
                //Get tournament match list
                getMatchList(tourData.category_id, tourData.gender, tourData.round);
            } else {
                setTournament({});//Set empty
            }
        })
        .catch((error) => {
            setLaoding(false);
            ToastsStore.error('Looks like there was a problem');
            console.log('Looks like there was a problem: \n', error);
        });        
    }, [setTournament, setGender, setGenderArr, setCategoryId, setCategoryArr, setRound, setroundGroup]);
    /*console.log(categoryArr);
    console.log(roundGroup);*/
    /**
     * On change gender dropdown update gender
     * @param {*} e 
     */
    const genderChangeHandler = (e) => {
        e.preventDefault();
        let curGender = e.target.value;
        setGender(curGender);
        getMatchList(category_id, curGender, round);
    }
    /**
     * On change category dropdown update category ID
     * @param {*} e 
     */
    const categoryChangeHandler = (e) => {
        e.preventDefault();
        let curCategoryId = e.target.value;
        setCategoryId(curCategoryId);
        getMatchList(curCategoryId, gender, round);
    }
    /**
     * On change round dropdown update round
     * @param {*} e 
     */
    const roundChangeHandler = (e) => {
        e.preventDefault();
        let curRound = e.target.value;
        setRound(curRound);
        getMatchList(category_id, gender, curRound);
    }
    /**
     * Get match list from the server
     * @param {*} category_id 
     * @param {*} gender 
     * @param {*} round 
     */
    const getMatchList = (category_id, gender, round) => {
        //console.log(category_id+'=='+gender+'=='+round);
        let params = {
            'tour_id': tour_id,
            'category_id': category_id,
            'gender': gender,
            'round': round
        }
        setLaoding(true);
        setLoaderText('matches list');
        Api.getTournamentMatchList(params)
        .then((result) => {
            setLaoding(false);
            let response = result.data;
            if(response.success) {
                setMatches(response.data);
            } else {                
                setMatches([{}]);//Set empty
            }
        })
        .catch((error) => {
            setLaoding(false);
            ToastsStore.error('Looks like there was a problem');
            console.log('Looks like there was a problem: \n', error);
        });
    }
    /**
     * Store selected match into localstorage and redirect propery page
     */
    const setMatchProperties = (e) => {
        e.preventDefault();
        let matchId = e.target.dataset.matchid;        
        matches.map((item, i) => {
            if(Object.keys(item).length > 0) {
                if(parseInt(item.id)===parseInt(matchId)) {
                    item['won_toss'] = "";
                    item['made_choice'] = "";
                    item['made_choice'] = "";
                    item['chair_left'] = "";
                    item['chair_right'] = "";
                    item['service_first'] = "";
                    item['service_first'] = "";
                    item['match_format'] = 0;
                    window.localStorage.setItem('tournament', JSON.stringify(tournament));
                    window.localStorage.setItem('match', JSON.stringify(item));
                }
            }
            return true;
        });
        history.push("/tournament-match-properties");
    }
    
    return(
        <>
            <div className={`loader ${isLoading ? 'show'  : 'hide'}`}>
                <div className="container align-items-center">
                    <span className="mr-2"><ScaleLoader color={'#ed1d25'} /></span>
                    <span>Please hold on while we are fetching your {loaderText} from the server</span>
                </div>
            </div>
            <Header back_url="/dashboard" />
            <main>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-header d-flex justify-content-between">
                                <div className="page-title">
                                    <h6 className="small mb-0">Tournament: {tournament.name}</h6>
                                    <h6 className="small mb-3">WeekOf: {tournament.weekof_title}</h6>
                                </div>
                                <div className="form-inline mb-3">
                                    <select name="gender" className="form-control form-control-sm mr-2" value={gender} onChange={genderChangeHandler}>
                                        <option value="">Gender</option>
                                        {genderArr.map((item, i) => {
                                            return <option key={i} value={item.value}>{item.title}</option>;
                                        })}
                                    </select>
                                    <select name="category" className="form-control form-control-sm mr-2" value={category_id} onChange={categoryChangeHandler}>
                                        <option value="">Category</option>
                                        <CategoryDropdown
                                            gender={gender}
                                            categoryArr={categoryArr}
                                        />
                                    </select>                                    
                                    <select name="round" className="form-control form-control-sm mr-2" value={round} onChange={roundChangeHandler}>
                                        <option value="">Round</option>
                                        <RoundDropdown
                                            gender={gender}
                                            category_id={category_id}
                                            roundGroup={roundGroup}
                                        />
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="table-responsive-sm table-responsive-md">
                                <table className="table table-bordered table-hover mobile-view text-center">
                                    <thead className="bg-light">
                                        <tr>
                                            <th with="5%">SNo</th>
                                            <th with="10%">Court</th>
                                            <th with="15%">Category</th>
                                            <th with="25%">Player/Team One</th>
                                            <th with="5%">Vs</th>
                                            <th with="25%">Player/Team Two</th>
                                            <th with="15%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {matches.map((item, i) => {
                                            return Object.keys(item).length > 0 ?
                                                <tr key={i}>
                                                    <td className="tdsno">{i+1}</td>
                                                    <td className="col-6-lt">
                                                        <div className="d-flex flex-column">
                                                            <span className="thshow">Court</span>
                                                            <span>{item.court_name}</span>
                                                        </div>
                                                    </td>
                                                    <td className="col-6-lt">
                                                        <div className="d-flex flex-column">
                                                            <span className="thshow">Category</span>
                                                            <span>{item.category_title}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-column">
                                                            <span className="thshow">Player/Team One</span>
                                                            <span className="text-inline">
                                                                <img src={item.player_one_image} className="img-thumbnail rounded-circle mr-2" alt="player img" />
                                                                {item.player_one_name}
                                                            </span>
                                                            <span className={`text-inline ${item.partner_one_name.length===0 ? 'd-none' : ''}`}>
                                                                <img src={item.partner_one_image} className="img-thumbnail rounded-circle mr-2" alt="player img" />
                                                                {item.partner_one_name}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>Vs</td>
                                                    <td>
                                                        <div className="d-flex flex-column">
                                                            <span className="thshow">Player/Team Two</span>
                                                            <span className="text-inline">
                                                                <img src={item.player_two_image} className="img-thumbnail rounded-circle mr-2" alt="player img" />
                                                                {item.player_two_name}
                                                            </span>
                                                            <span className={`text-inline ${item.partner_two_name.length===0 ? 'd-none' : ''}`}>
                                                                <img src={item.partner_two_image} className="img-thumbnail rounded-circle mr-2" alt="player img" />
                                                                {item.partner_two_name}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="action">
                                                        <div>
                                                            <span className="thshow">Action</span>
                                                            <button className="btn btn-primary btn-mini btn-block" data-matchid={item.id} onClick={setMatchProperties}>To Continue</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr key={i}>
                                                    <td colSpan="7">!! No Records !!</td>
                                                </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default MatchList;