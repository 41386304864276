import React from "react";
import {
    Route,
    BrowserRouter as Router,
    Switch
} from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoute";
/**
 * Import our app pages
 */
import Landing from "./Pages/Landing/Landing";
import Login from "./Pages/Login/Login";
import NotFound from "./Pages/NotFound/NotFound";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MatchList from "./Pages/MatchList/MatchList";
import MatchProperty from "./Pages/MatchProperty/MatchProperty";
import MatchScore from "./Pages/MatchScore/MatchScore";

const Routes = (props) => (
    <Router {...props}>
        <Switch>
            <Route exact path="/" component={Landing} />
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/tournament-match-list-:tour_id" component={MatchList} />
            <ProtectedRoute path="/tournament-match-properties" component={MatchProperty} />
            <ProtectedRoute path="/tournament-match-score" component={MatchScore} />
            <Route path="*" component={NotFound} />
        </Switch>
    </Router>
);

export default Routes;